<template>
    <div v-if="modalAtivo" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ml-160 mt-10 justify-center items-center flex">
        <!--content-->
        <div class="border rounded-lg shadow-lg flex-col w-full bg-gray-100 outline-none focus:outline-none">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalPequeno",
    props: ["modalAtivo"],
    setup() {}
}
</script>