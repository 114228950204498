import { createApp } from "vue";
import App from "@/App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios';
import VueSweetalert2 from "vue-sweetalert2";
import Swal from "sweetalert2/dist/sweetalert2.js";

// styles
import "sweetalert2/dist/sweetalert2.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// define o host da api laravel
axios.defaults.baseURL = 'https://bpachannel.sofs.com.br/api';

// quando logado setar o token para validação das rotas autenticadas
axios.defaults.withCredentials = true;


//configuração do Toast
const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    timer: 5000,
    timerProgressBar: true,
    showConfirmButton: false
  });

//salva a configuração global na palavra "Toast"
window.Toast = Toast;  

createApp(App).use(router).use(store).use(VueSweetalert2).mount("#app");

