<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
      >
      <div class="rounded-t mb-0 px-4 py-5 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-3xl"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              Tabela de Postagens
            </h3>
          </div>
          <router-link to="/admin/postagens/criar">
            <button 
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button" 
            >
              <i class="fas fa-user"></i> Criar Postagem
            </button>
          </router-link>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                ID
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Titulo
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Conteúdo
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Imagem
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Autor
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Categoria
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Slug
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Publicado Em
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="postagem in postagens" :key="postagem.id">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
              {{ postagem.id }} 
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
              {{ postagem.titulo }} 
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
              <router-link
              :to="`/admin/postagens/${postagem.id}/conteudo`"><i class="fas fa-eye"></i></router-link>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
                <a target="_blank" rel="noopener noreferrer" :href="postagem.imagem"> <img :src="postagem.imagem" height="50"> </a>              
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
                {{ postagem.autor.nome_usuario }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
                {{ postagem.categoria.nome }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
                {{ postagem.slug }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
                {{ dateTime(postagem.publicado_em) }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4 text-center"
              >
                <div class="text-center">
                      <router-link
                      :to="`/admin/postagens/${postagem.id}/editar`"
                      href="javascript:void(0)"
                      v-if="usuarioAutenticado.canEdit('postagens')" 
                      >
                      <i class="fas fa-pen text-sm text-yellow"></i>
                      </router-link>
                      <a
                      href="javascript:void(0)" 
                      @click="del(postagem)"
                      v-if="usuarioAutenticado.canDelete('postagens')">
                        <i class="fas fa-trash text-sm text-red-500 ml-3"></i>
                      </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginacao :ultima-pagina="ultimaPagina" @mudar-pagina="load($event)"/>
      </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import Paginacao from '@/components/Navbars/Paginacao';
import { useStore } from 'vuex';

export default {
    name: "Usuarios",
    components: {
      Paginacao
    },
    methods: {
      dateTime(value) {
        return moment(value).format("DD/MM/YYYY");
      },
    },
    setup() {
        const postagens = ref([]);
        const ultimaPagina = ref(0);

        const store = useStore();

        const usuarioAutenticado = computed(() => store.state.Usuario.usuario);

        const load = async (pagina = 1) => {
            const response = await axios.get(`postagens?page=${pagina}`);

            postagens.value = response.data.data;
            ultimaPagina.value = response.data.meta.last_page;
        }

        const swalWithTailwindButtons = Swal.mixin({
            customClass: {
            confirmButton: 'bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150',
            cancelButton: 'bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
            },
            buttonsStyling: false
        })

        onMounted(load);

        const del = async (postagem) => {
          await swalWithTailwindButtons.fire({
            title: `Você tem Certeza que deseja Deletar a Postagem ${postagem.titulo}?`,
            text: "Esta ação não pode ser revertida!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'CONFIRMAR',
            cancelButtonText: 'CANCELAR',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete(`postagens/${postagem.id}`).then(response => {
                swalWithTailwindButtons.fire(
                  'Sucesso!',
                  response.data.message,
                  'success'
                )
              }).catch(error => {
                Toast.fire("Ops...", error.response.data.message, "error");
              });

              // filtra os valores dos usuários, retirando o usuário que foi excluido pelo id
              postagens.value = postagens.value.filter((u) => u.id !== postagem.id);     
            } else if (
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithTailwindButtons.fire(
                'Cancelado',
                'O Registro não foi Deletado!',
                'error'
              )
            }
          })
        }
        return {
            postagens,
            usuarioAutenticado,
            ultimaPagina,
            load,
            del
        }
    },
   props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      }, 
    },
}
</script>

<style>

</style>