<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
        <div class="flex-auto p-10 lg:p-10">
            <h4 class="text-2xl font-semibold">
                Criação de Postagem              
            </h4>
            <form @submit.prevent="enviar">
                <div class="relative w-full mb-3 mt-8">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="nome"
                >
                    Titulo
                </label>
                <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    required
                    placeholder="Digite o Titulo"
                    v-model="titulo"
                />
                </div>

                <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="conteudo"
                >
                    Conteúdo
                </label>
                <QuillEditor 
                    theme="snow" 
                    toolbar="full" 
                    :options="options"
                    v-model:content="conteudo"
                    content-type="html"
                    required
                />
                </div>

                <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="imagem"
                >
                    Imagem
                </label>
                <div class="flex">
                    <input type="text" required class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" name="image" v-model="imagem" />

                    <ImagemUpload @file-uploaded="imagem = $event">
                    </ImagemUpload>
                </div>
                <div class="border p-2 mt-3" v-if="imagem">
                    <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Preview da Imagem:</label>
                    <img :src="imagem" width="400" height="300"/>   
                </div>

                </div>

                <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="perfil"
                >
                    Categoria
                </label>
                <VueMultiselect 
                    v-model="categoria" 
                    :options="categorias" 
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Selecione a Categoria"
                    label="nome" 
                    track-by="nome"
                    class="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <template #noResult>
                            Oops! Nenhum Valor Encontrado.
                        </template>
                </VueMultiselect>
                </div>

                <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="autorId"
                >
                    Autor
                </label>
                <VueMultiselect 
                    v-model="autor" 
                    :options="usuarios" 
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Selecione o Autor"
                    required
                    label="nome_usuario" 
                    track-by="nome_usuario"
                    class="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <template #noResult>
                            Oops! Nenhum Valor Encontrado.
                        </template>
                </VueMultiselect>
                </div>
                <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="publicado_em"
                >
                    Publicado Em
                </label>
                <input
                    type="date"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    required
                    v-model="publicadoEm"
                />
                </div>
                <div class="text-center mt-6">
                <router-link to="/admin/postagens">
                    <button 
                    class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                    type="button" 
                    >
                        Cancelar
                    </button>
                </router-link>
                <button 
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="submit" 
                >
                    Enviar
                </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueMultiselect from 'vue-multiselect'
import Swal from 'sweetalert2';
import ImagemUpload from '@/components/Imagem/ImagemUpload.vue';
import { useRouter } from 'vue-router';

export default {
    name: "CriarPostagem",
    components: {
      QuillEditor,
      VueMultiselect,
      ImagemUpload
    },
    setup () {
        const titulo = ref("");
        const conteudo = ref("");
        const imagem = ref("");
        const categoria = ref(null);
        const categorias = ref([]);
        const autor = ref(null);
        const usuarios = ref([]);
        const publicadoEm = ref("");
        const router = useRouter();

        const swalWithTailwindButtons = Swal.mixin({
            customClass: {
            confirmButton: 'bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150',
            cancelButton: 'bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
            },
            buttonsStyling: false
        })

        onMounted(async () => {
            const categoriasResponse = await axios.get('categorias');
            const usuariosResponse = await axios.get('usuarios');

            categorias.value = categoriasResponse.data.data; 
            usuarios.value = usuariosResponse.data.data;
        })

        const enviar = async () => {
          await axios.post('postagens', {
                titulo: titulo.value,
                conteudo: conteudo.value,
                imagem: imagem.value,
                autor_id: autor.value.id,
                categoria_id: categoria.value.id,
                publicado_em: publicadoEm.value
          }).then(response => {
            swalWithTailwindButtons.fire(
                'Sucesso!',
                response.data.message,
                'success'
            ).then(() => {
                router.push("/admin/postagens");
            });
          }).catch(error => {
            Toast.fire("Ops...", error.response.data.message, "error");
          });
        }

        return {
            titulo,
            conteudo,
            imagem,
            categoria,
            categorias,
            autor,
            usuarios,
            publicadoEm,
            enviar,
            options: {
                modules: {
                    toolbar: ['bold', 'italic', 'underline']
                },
                placeholder: 'Escreva o corpo da Postagem...',
                theme: 'snow'
            }
        }
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      },
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>