<template>
  <ModalPequeno :modalAtivo="modalCriacao">
          <button class="p-3 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-xl leading-none font-semibold outline-none focus:outline-none" v-on:click="cancelarCriacao()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-3xl block outline-none focus:outline-none">
                ×
              </span>
          </button>
          <div class="flex-auto p-10 lg:p-10">
          
            <h4 class="text-2xl font-semibold">
                Criação de Perfil              
            </h4>
            <form @submit.prevent="criar">
              <div class="relative w-full mb-3 mt-8">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="nome"
                >
                  Nome
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite um nome"
                  v-model="nome"
                />
              <div class="relative w-full mb-3 mt-5 text-left">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="permissoes"
                >
                  Permissões
                </label>
                <div class="grid gap-x-4 grid-cols-2">
                  <div class="mb-2 mt-2 text-left" v-for="permissao in permissoes" :key="permissao.id">
                    <input
                    type="checkbox" 
                    class="h-4 border-0 px-2 py-2 bg-white rounded text-sm shadow focus:outline-none ease-linear transition-all duration-150"
                    :value="permissao.id"
                    @change="selecionar(permissao.id, $event.target.checked)" />
                    <label class="text-xs ml-2">{{ permissao.nome }}</label>
                  </div>
                </div>
              </div>
              </div>
              <div class="text-center mt-6">
                <button 
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="button" 
                v-on:click="cancelarCriacao">
                  Cancelar
                </button>
                <button 
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="submit" 
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
  </ModalPequeno>

  <ModalPequeno :modalAtivo="modalEdicao">
          <button class="p-3 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-xl leading-none font-semibold outline-none focus:outline-none" v-on:click="cancelarEdicao()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-3xl block outline-none focus:outline-none">
                ×
              </span>
          </button>
          <div class="flex-auto p-10 lg:p-10">
          
            <h4 class="text-2xl font-semibold">
                Edição de Perfil              
            </h4>
            <form @submit.prevent="editar(perfil_id)">
              <div class="relative w-full mb-3 mt-8">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="nome"
                >
                  Nome
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite um nome"
                  v-model="nome"
                />
              <div class="relative w-full mb-3 mt-5 text-left">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="permissoes"
                >
                  Permissões
                </label>
                <div class="grid gap-x-4 grid-cols-2">
                  <div class="mb-2 mt-2 text-left" v-for="permissao in permissoes" :key="permissao.id">
                    <input
                    type="checkbox" 
                    class="h-4 border-0 px-2 py-2 bg-white rounded text-sm shadow focus:outline-none ease-linear transition-all duration-150"
                    :value="permissao.id"
                    @change="selecionar(permissao.id, $event.target.checked)"
                    :checked="checado(permissao.id)" />
                    <label class="text-xs ml-2">{{ permissao.nome }}</label>
                  </div>
                </div>
              </div>
              </div>
              <div class="text-center mt-6">
                <button 
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="button" 
                v-on:click="cancelarEdicao">
                  Cancelar
                </button>
                <button 
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="submit" 
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
  </ModalPequeno>

  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
      >
      <div class="rounded-t mb-0 px-4 py-5 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-3xl"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              Tabela de Perfis
            </h3>
          </div>
          <button 
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
          type="button" 
          v-on:click="alterarModalCriacao()"
          >
            <i class="fas fa-id-card"></i> Criar Perfil
          </button>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 align-center border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                ID
              </th>
              <th
                class="px-6 align-center border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Nome
              </th>
              <th
                class="px-6 align-center border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="perfil in perfis" :key="perfil.id">
              <th
                class="border-t-0 px-6 align-center border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
              {{ perfil.id }} 
              </th>
              <td
                class="border-t-0 px-6 align-center border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
              {{ perfil.nome }} 
              </td>
              <td
                class="border-t-0 px-6 align-center border-l-0 border-r-0 text-lg whitespace-nowrap p-4 text-center"
              >
                <div class="text-center">
                      <a
                      href="javascript:void(0)" 
                      @click="carregar(perfil.id)"
                      v-if="usuarioAutenticado.canEdit('perfis')">
                      <i class="fas fa-pen text-sm text-yellow"></i>
                      </a>
                      <a
                      href="javascript:void(0)" 
                      @click="del(perfil)"
                      v-if="usuarioAutenticado.canDelete('perfis')">
                        <i class="fas fa-trash text-sm text-red-500 ml-3"></i>
                      </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginacao :ultima-pagina="ultimaPagina" @mudar-pagina="load($event)"/>
      </div>
  </div>
</template>

<script>
import {ref, onMounted, computed} from 'vue';
import axios from 'axios';
import ModalPequeno from '@/components/Modais/ModalPequeno';
import Paginacao from '@/components/Navbars/Paginacao';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';

export default {
    name: "Perfis",
    components: {ModalPequeno, Paginacao},
    setup() {
        const store = useStore();
        
        const usuarioAutenticado = computed(() => store.state.Usuario.usuario);

        const perfis = ref([]);
        const ultimaPagina = ref(0);
        const modalCriacao = ref(false);
        const modalEdicao = ref(false);
        
        const perfil_id = ref(0);
        const nome = ref('');
        const permissoes = ref([]);
        const selecionado = ref([]);

        const swalWithTailwindButtons = Swal.mixin({
          customClass: {
            confirmButton: 'bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150',
            cancelButton: 'bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
          },
          buttonsStyling: false
        })

        const load = async (pagina = 1) => {
          const perfisResponse = await axios.get('perfis');

          perfis.value = perfisResponse.data.data;
          ultimaPagina.value = perfisResponse.data.meta.last_page;

          const permissoesResponse = await axios.get('permissoes');

          permissoes.value = permissoesResponse.data.data; 
        }

        onMounted(load);

        const alterarModalCriacao = () => {
          modalCriacao.value = !modalCriacao.value;
        }

        const alterarModalEdicao = () => {
          modalEdicao.value = !modalEdicao.value;
        }

        const cancelarCriacao = async() => {
          alterarModalCriacao();
          nome.value = '';
          selecionado.value = [];
        }
        
        const cancelarEdicao = async() => {
          alterarModalEdicao();
          nome.value = '';
          selecionado.value = [];
        }

        // função para selecionar os valores do checkbox de permissões
        const selecionar = (id, checado) => {
            if(checado) {
                // se tiver marcado, colocar o indice selecionado e o id da permissão na variável selecionado
                selecionado.value = [...selecionado.value, id];
                return;
            }

            selecionado.value = selecionado.value.filter(s => s !== id);
        }

        // verifica se existe 1 ou mais valores de acordo com o id da permissão e coloca como selecionado
        const checado = (id) => selecionado.value.some(s => s === id);

        const criar = async () => {
            await axios.post('perfis', {
              nome: nome.value,
              permissoes: selecionado.value,
            }).then(response => {
              alterarModalCriacao();
              swalWithTailwindButtons.fire(
                'Sucesso!',
                response.data.message,
                'success'
              ).then(() => {
                window.location.reload();
              });
            }).catch(error => {
              Toast.fire("Ops...", error.response.data.message, "error");
            });
        }

        const carregar = async(id) => {
          const perfilCall = await axios.get(`perfis/${id}`);

          const perfil = perfilCall.data.data;

          perfil_id.value = perfil.id;
          nome.value = perfil.nome;
          selecionado.value = perfil.permissoes.map(p => p.id);
          
          alterarModalEdicao();
        }

        const editar = async(id) => {
          await axios.put(`perfis/${id}`, {
            nome: nome.value,
            permissoes: selecionado.value,
          }).then(response => {
            cancelarEdicao();
            swalWithTailwindButtons.fire(
              'Sucesso!',
              response.data.message,
              'success'
            ).then(() => {
              window.location.reload();
            });
          }).catch(error => {
            Toast.fire("Ops...", error.response.data.message, "error");
          });
        }

        const del = async (perfil) => {
          await swalWithTailwindButtons.fire({
            title: `Você tem Certeza que deseja Deletar o Perfil ${perfil.nome}?`,
            text: "Esta ação não pode ser revertida!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'CONFIRMAR',
            cancelButtonText: 'CANCELAR',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete(`perfis/${perfil.id}`).then(response => {
                swalWithTailwindButtons.fire(
                  'Sucesso!',
                  response.data.message,
                  'success'
                )
              }).catch(error => {
                Toast.fire("Ops...", error.response.data.message, "error");
              });

              perfis.value = perfis.value.filter((p) => p.id !== perfil.id);     
            } else if (
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithTailwindButtons.fire(
                'Cancelado',
                'O Registro não foi Deletado!',
                'error'
              )
            }
          })
        }

        return {
            load,
            perfis,
            usuarioAutenticado,
            perfil_id,
            nome,
            permissoes,
            ultimaPagina,
            modalCriacao,
            modalEdicao,
            alterarModalCriacao,
            alterarModalEdicao,
            cancelarCriacao,
            cancelarEdicao,
            selecionado,
            selecionar,
            criar,
            carregar,
            checado,
            editar,
            del
        }
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      },
    },
}
</script>

<style>

</style>