<template>
    <!-- Modal de criação de Usuário-->
    <ModalPequeno :modalAtivo="modalCriacao">
          <button class="p-3 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-xl leading-none font-semibold outline-none focus:outline-none" v-on:click="cancelarCriacao()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-3xl block outline-none focus:outline-none">
                ×
              </span>
          </button>
          <div class="flex-auto p-10 lg:p-10">
          
            <h4 class="text-2xl font-semibold">
                Criação de Usuário              
            </h4>
            <form @submit.prevent="criar">
              <div class="relative w-full mb-3 mt-8">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="nome"
                >
                  Nome
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite um nome"
                  v-model="nome"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="nome-usaurio"
                >
                  Nome de Usuário
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite um nome para usuário"
                  v-model="nomeUsuario"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="perfil"
                >
                  Perfil
                </label>
                <VueMultiselect 
                    v-model="perfil" 
                    :options="perfis" 
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Selecione o Perfil"
                    label="nome" 
                    track-by="nome"
                    class="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <template #noResult>
                            Oops! Nenhum Valor Encontrado.
                        </template>
                </VueMultiselect>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Digite um Email"
                  required
                  v-model="email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="senha"
                >
                  Senha
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Digite uma Senha"
                  required
                  v-model="senha"
                />
              </div>
              <div class="text-center mt-6">
                <button 
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="button" 
                v-on:click="cancelarCriacao">
                  Cancelar
                </button>
                <button 
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="submit" 
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
    </ModalPequeno>

    <!-- Modal de edição de Usuário-->
    <ModalPequeno :modalAtivo="modalEdicao">
          <button class="p-3 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-xl leading-none font-semibold outline-none focus:outline-none" v-on:click="cancelarEdicao()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-3xl block outline-none focus:outline-none">
                ×
              </span>
          </button>
          <div class="flex-auto p-10 lg:p-10">
          
            <h4 class="text-2xl font-semibold">
                Edição de Usuário              
            </h4>
            <form @submit.prevent="editar(usuario_id)">
              <div class="relative w-full mb-3 mt-8">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="nome"
                >
                  Nome
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite um nome"
                  v-model="nome"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="nome-usaurio"
                >
                  Nome de Usuário
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite um nome para usuário"
                  v-model="nomeUsuario"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="perfil"
                >
                  Perfil
                </label>
                <VueMultiselect 
                    v-model="perfil" 
                    :options="perfis" 
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Selecione o Perfil"
                    label="nome" 
                    track-by="nome"
                    class="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <template #noResult>
                            Oops! Nenhum Valor Encontrado.
                        </template>
                </VueMultiselect>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Digite um Email"
                  required
                  v-model="email"
                />
              </div>
              <div class="text-center mt-6">
                <button 
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="button" 
                v-on:click="cancelarEdicao">
                  Cancelar
                </button>
                <button 
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                type="submit" 
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
    </ModalPequeno>

    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
      >
      <div class="rounded-t mb-0 px-4 py-5 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-3xl"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              Tabela de Usuários
            </h3>
          </div>
          <button 
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
          type="button" 
          v-on:click="alterarModalCriacao()"
          v-if="usuarioAutenticado.canEdit('usuarios')"
          >
            <i class="fas fa-user"></i> Criar Usuário
          </button>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                ID
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Nome
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Nome de Usuario
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Email
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Perfil
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="usuario in usuarios" :key="usuario.id">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
              {{ usuario.id }} 
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
              {{ usuario.nome }} 
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
                {{ usuario.nome_usuario }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
                {{ usuario.email }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-center"
              >
                {{ usuario.perfil.nome }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4 text-center"
              >
                <div class="text-center">
                      <a
                      href="javascript:void(0)" 
                      @click="carregar(usuario.id)"
                      v-if="usuarioAutenticado.canEdit('usuarios')">
                        <i class="fas fa-pen text-sm text-yellow"></i>
                      </a>
                      <a
                      href="javascript:void(0)" 
                      @click="del(usuario)"
                      v-if="usuarioAutenticado.canDelete('usuarios')">
                        <i class="fas fa-trash text-sm text-red-500 ml-3"></i>
                      </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginacao :ultima-pagina="ultimaPagina" @mudar-pagina="load($event)"/>
      </div>
    </div>
  </template>
<script>  
import {ref, onMounted, computed} from 'vue';
import axios from 'axios';
import ModalPequeno from '@/components/Modais/ModalPequeno';
import Paginacao from '@/components/Navbars/Paginacao';
import Swal from 'sweetalert2';
import VueMultiselect from 'vue-multiselect'
import { useStore } from 'vuex';

export default {
    name: "Usuarios",
    components: {ModalPequeno, Paginacao, VueMultiselect},
    setup() {
      const usuarios = ref([]);
      const ultimaPagina = ref(0);
      const store = useStore();

      const modalCriacao = ref(false);
      const modalEdicao = ref(false);

      const usuarioAutenticado = computed(() => store.state.Usuario.usuario);

      const usuario_id = ref(0);
      const nome = ref('');
      const nomeUsuario = ref('');
      const email = ref('');
      const senha = ref('');
      const perfil = ref(null);
      const perfis = ref([]);

      const swalWithTailwindButtons = Swal.mixin({
        customClass: {
          confirmButton: 'bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150',
          cancelButton: 'bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
        },
        buttonsStyling: false
      })

      const load = async (pagina = 1) => {
        const perfisResponse = await axios.get('perfis');
        const usuariosResponse = await axios.get(`usuarios?page=${pagina}`);

        perfis.value = perfisResponse.data.data;
        usuarios.value = usuariosResponse.data.data;
        ultimaPagina.value = usuariosResponse.data.meta.last_page;
      }

      // vai carregar os usuários da api laravel na medida em que monta o DOM
      onMounted(load);

      const alterarModalCriacao = () => {
        modalCriacao.value = !modalCriacao.value;
      }

      const alterarModalEdicao = () => {
        modalEdicao.value = !modalEdicao.value;
      }

      const criar = async() => {
        await axios.post('usuarios', {
            nome: nome.value,
            nome_usuario: nomeUsuario.value,
            email: email.value,
            perfil_id: perfil.value.id,
            senha: senha.value,
        }).then(response => {
          alterarModalCriacao();
          swalWithTailwindButtons.fire(
            'Sucesso!',
            response.data.message,
            'success'
          ).then(() => {
            window.location.reload();
          });
        }).catch(error => {
          Toast.fire("Ops...", error.response.data.message, "error");
        });
      }

      const cancelarCriacao = async() => {
        alterarModalCriacao();
        nome.value = '';
        nomeUsuario.value = '';
        email.value = '';
        perfil.value = null;
        senha.value = '';
      }
      const cancelarEdicao = async() => {
        alterarModalEdicao();
        nome.value = '';
        nomeUsuario.value = '';
        email.value = '';
        perfil.value = null;
        senha.value = '';
      }

      const carregar = async(id) => {
        const usuarioCall = await axios.get(`usuarios/${id}`);

        // pega as informações do parâmetro id do usuário selecionado na tabela
        const usuario = usuarioCall.data.data;

        usuario_id.value = usuario.id;
        nome.value = usuario.nome;
        nomeUsuario.value = usuario.nome_usuario;
        email.value = usuario.email;
        perfil.value = usuario.perfil;
        alterarModalEdicao();
      }

      const editar = async(id) => {
        await axios.put(`usuarios/${id}`, {
          nome: nome.value,
          nome_usuario: nomeUsuario.value,
          email: email.value,
          perfil_id: perfil.value.id,
        }).then(response => {
          cancelarEdicao();
          swalWithTailwindButtons.fire(
            'Sucesso!',
            response.data.message,
            'success'
          ).then(() => {
            window.location.reload();
          });
        }).catch(error => {
          Toast.fire("Ops...", error.response.data.message, "error");
        });
      }

      // função de deleção
      const del = async (usuario) => {
        await swalWithTailwindButtons.fire({
          title: `Você tem Certeza que deseja Deletar Usuário ${usuario.nome_usuario}?`,
          text: "Esta ação não pode ser revertida!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'CONFIRMAR',
          cancelButtonText: 'CANCELAR',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete(`usuarios/${usuario.id}`).then(response => {
              swalWithTailwindButtons.fire(
                'Sucesso!',
                response.data.message,
                'success'
              )
            }).catch(error => {
              Toast.fire("Ops...", error.response.data.message, "error");
            });

            // filtra os valores dos usuários, retirando o usuário que foi excluido pelo id
            usuarios.value = usuarios.value.filter((u) => u.id !== usuario.id);     
          } else if (
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithTailwindButtons.fire(
              'Cancelado',
              'O Registro não foi Deletado!',
              'error'
            )
          }
        })
      }

      return {
        load,
        usuarioAutenticado,
        usuarios,
        perfis,
        ultimaPagina,
        modalCriacao,
        modalEdicao,
        alterarModalCriacao,
        alterarModalEdicao,
        cancelarCriacao,
        cancelarEdicao,
        usuario_id,
        nome,
        nomeUsuario,
        email,
        senha,
        perfil,
        criar,
        carregar,
        editar,
        del
      };
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      },
    },
  };

</script>
  