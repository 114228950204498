<template>
  <div>
    <main class="profile-page">
      <section class="relative py-16">
      </section>
      <section class="relative py-16">
        <div class="container ml-80 px-6">
          <div
            class="relative flex flex-col min-w-0 break-words bg-gray-100 max-w-4xl mb-6 shadow-xl rounded-lg -mt-64"
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="imagemUsuario"
                      class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </div>
                </div>
                <div
                  class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
                >
                  <div class="py-6 px-3 mt-32 sm:mt-0">
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4 lg:order-1">
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="text-4xl font-semibold leading-normal text-blueGray-700 mb-2"
                >
                  {{ nome }}
                </h3>
               
                <div class="mx-auto w-full lg:w-6/12 px-6 mt-8">
                    <h4 class="text-2xl font-semibold">
                        Informações da Conta              
                    </h4>
                    <form @submit.prevent="enviarInfo">
                        <div class="relative w-full mb-3 mt-6">
                        <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="nome"
                        >
                            Nome
                        </label>
                        <input
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            required
                            placeholder="Digite um nome"
                            v-model="nome"
                        />
                        </div>

                        <div class="relative w-full mb-3">
                        <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="nome-usuario"
                        >
                            Nome de Usuário
                        </label>
                        <input
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            required
                            placeholder="Digite um nome para usuário"
                            v-model="nomeUsuario"
                        />
                        </div>

                        <div class="relative w-full mb-3">
                        <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="email"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Digite um Email"
                            required
                            v-model="email"
                        />
                        </div>
                        <div class="text-center mt-6">
                        <button 
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                        type="submit" 
                        >
                            SALVAR
                        </button>
                        </div>
                    </form>
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="mx-auto w-full lg:w-6/12 px-6">
                    <h4 class="text-2xl font-semibold">
                        Atualizar Senha              
                    </h4>
                    <form @submit.prevent="enviarSenha">
                        <div class="relative w-full mb-3">
                        <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2 mt-4"
                            htmlFor="senha"
                        >
                            Senha
                        </label>
                        <input
                            type="password"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            required
                            placeholder="Digite uma senha"
                            v-model="senha"
                        />
                        </div>

                        <div class="relative w-full mb-3">
                        <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="confirmar-senha"
                        >
                            Confirmar Senha
                        </label>
                        <input
                            type="password"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Confirmar a Senha"
                            required
                            v-model="confirmarSenha"
                        />
                        </div>
                        <div class="text-center mt-6">
                        <button 
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                        type="submit" 
                        >
                            SALVAR
                        </button>
                        </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import imagemUsuario from "@/assets/img/default-user.jpg";
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { Usuario } from "../../../classes/usuario";
import Swal from 'sweetalert2';

export default {
    name: "Perfil",
    setup() {
        const nome = ref('');
        const nomeUsuario = ref('');
        const email = ref('');
        const senha = ref('');
        const confirmarSenha = ref('');
        const store = useStore();
        
        const swalWithTailwindButtons = Swal.mixin({
            customClass: {
            confirmButton: 'bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150',
            cancelButton: 'bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
            },
            buttonsStyling: false
        })
        
        onMounted(async () => {
            const usuario = computed(() => store.state.Usuario.usuario);

            nome.value = usuario.value.nome;
            nomeUsuario.value = usuario.value.nome_usuario;
            email.value = usuario.value.email;

        })

        const enviarInfo = async () => {
            const response = await axios.put('usuario/info', {
                nome: nome.value,
                nome_usuario: nomeUsuario.value,
                email: email.value
            }).then(response => {
                swalWithTailwindButtons.fire(
                    'Sucesso!',
                    response.data.message,
                    'success'
                );
            }).catch(error => {
                Toast.fire("Ops...", error.response.data.message, "error");
            });

            const u = response.data;

            // dispara o evento de alteração do usuario
            await store.dispatch('Usuario/setUser', new Usuario(
                u.id,
                u.nome,
                u.nome_usuario,
                u.email,
                u.perfil,
                u.permissoes,
            ))
        }

        const enviarSenha = async () => {
            const response = await axios.put('usuario/senha', {
                senha: senha.value,
                senha_confirmacao: confirmarSenha.value
            }).then(response => {
                swalWithTailwindButtons.fire(
                    'Sucesso!',
                    response.data.message,
                    'success'
                );
            }).catch(error => {
                Toast.fire("Ops...", error.response.data.message, "error");
            });
            senha.value = '';
            confirmarSenha.value = '';
        }

        return {
            imagemUsuario,
            nome,
            nomeUsuario,
            email,
            senha,
            confirmarSenha,
            enviarInfo,
            enviarSenha
        }
    }
}
</script>

<style>

</style>