<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <header-stats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <router-view v-if="usuario?.id" />
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import {onMounted, ref} from 'vue';
import axios from 'axios';

import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import { useStore } from 'vuex';
import { Usuario } from '@/classes/usuario';

export default {
  name: "Admin",
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
  },
  setup() {
      const usuario = ref();
      const store = useStore();

      // hook que verifica se os componentes do DOM foram montados
      onMounted(async () => {
        const response = await axios.get('usuario');

        const u = response.data.data;

        // dispara o evento de alteração do usuario
        await store.dispatch('Usuario/setUser', new Usuario(
          u.id,
          u.nome,
          u.nome_usuario,
          u.email,
          u.perfil,
          u.permissoes,
        ));

        // pega as informações do usuário logado
        usuario.value = u;
      });
      
      return {
        usuario
      }
    }
};
</script>
