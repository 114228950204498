import { Usuario } from '../classes/usuario';
import { Commit } from 'vuex';

export default {
    namespaced: true,
    state: {
        usuario: new Usuario(),
    },
    getters: {
    },
    mutations: {
        SET_USER: (state, usuario) => state.usuario = usuario,
    },
    actions: {
        setUser: ({commit}, usuario) => commit('SET_USER', usuario)
    },
}
