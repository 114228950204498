<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-bold-500 text-3xl text-center mb-5 font-bold">
              Cadastro
              <hr class="mt-4 border-b-1 border-blueGray-300" />
            </div>
            <form @submit.prevent="submit">
              <div class="relative w-full mb-3 mt-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Nome
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite seu Nome"
                  v-model="nome"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Nome de Usuáro
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite seu Nome de Usuario"
                  v-model="nomeUsuario"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite seu Email"
                  v-model="email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Senha
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite sua Senha"
                  v-model="senha"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Confirmar Senha
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Confirme Sua Senha"
                  v-model="senhaConfirmacao"
                />
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-redGray-100 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Cadastrar
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="flex-wrap relative">
          <div class="w-1 text-center">
            <router-link to="/login" class="text-blueGray-200">
              <h5>Já tem conta? Faça Login</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  name: "Cadastro",
  setup() {
    const nome = ref('');
    const nomeUsuario = ref('');
    const email = ref('');
    const senha = ref('');
    const senhaConfirmacao = ref('');
    const router = useRouter();

    const submit = async () => {
      await axios.post('cadastro', {
        nome: nome.value,
        nome_usuario: nomeUsuario.value,
        email: email.value,
        senha: senha.value,
        senha_confirmacao: senhaConfirmacao.value
      }).then(response => {
        router.push('/login');
        Toast.fire("Sucesso", response.data.message, "success");
      }).catch(error => {
        Toast.fire("Ops...", error.response.data.message, "error");
      });      
    }

    return {
        nome,
        nomeUsuario,
        email,
        senha,
        senhaConfirmacao,
        submit
    };
  },
};
</script>
