<template>
  <!-- Header -->
  <div class="relative bg-red-800 md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>
