<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="javascript:void(0)"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span
          class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
        >
          <img
            alt="..."
            class="w-full rounded-full align-middle border-none shadow-lg"
            :src="image"
          />
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        to="/admin/perfil"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Perfil
      </router-link>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="sair"
      >
        Sair 
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

import imagemUsuario from "@/assets/img/default-user.jpg";

import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: imagemUsuario,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
        
      }
    },
  },
  setup () {
    const router = useRouter();

    const sair = async () => {
      await axios.post('sair', {}).then(response => {
        router.push('/login');
        Toast.fire("Sucesso", response.data.message, "success");
      }).catch(error => {
        Toast.fire("Ops...", error.response.data.message, "error");
      });

    }

    return {
      sair
    }
  }
  
};
</script>
