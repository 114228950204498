<template>
  <div>
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="absolute top-0 w-full h-full bg-red-800 bg-no-repeat bg-full"
        ></div>
        <router-view />
      </section>
    </main>
  </div>
</template>
<script>

export default {
  setup() {
    return {
      
    };
  },
  components: {},
};
</script>
