<template>
    <div class="py-2">
        <nav class="block">
            <ul class="flex pl-0 rounded list-none flex-wrap justify-center">
                <li>
                <a href="javascript:void(0)" 
                    class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-500 bg-white text-red-500"
                    @click="anterior">
                    <i class="fas fa-chevron-left -ml-px"></i>
                </a>
                </li>
                <li v-for="page in ultimaPagina" v-bind:key="page">
                <a href="javascript:void(0)" 
                :class="[pagina === page ? active : inactive]"
                @click="clicarPagina(page)"
                >
                    {{ page }}
                </a>
                </li>
                <li>
                <a href="javascript:void(0)" 
                    class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-500 bg-white text-red-500"
                    @click="proximo">
                    <i class="fas fa-chevron-right -mr-px"></i>
                </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: "Paginacao",
    emits: ['mudar-pagina'],
    props: {
        ultimaPagina: Number,
    },
    setup(props, context) {
        const pagina = ref(1);

        const clicarPagina = async(page) => {
            pagina.value = page;
            context.emit('mudar-pagina', pagina.value);
        }

        // funções de paginação -> decrementar e incrementar paginas
        const anterior = async() => {
            if(pagina.value === 1) return;

            pagina.value--;

            // emite um evento para mudar a página
            context.emit('mudar-pagina', pagina.value);
        }

        const proximo = async() => {
            if(pagina.value === props.ultimaPagina) return;

            pagina.value++;

            // emite um evento para mudar a página
            context.emit('mudar-pagina', pagina.value);
        }

        return {
            pagina,
            anterior,
            proximo,
            clicarPagina,
            active: 'irst:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-500 text-white bg-red-500',
            inactive: 'first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-500 bg-white text-red-500',
        }
    }
}
</script>

<style>

</style>