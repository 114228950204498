<template>
  <div class="container mx-auto px-5 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-5">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-bold-500 text-3xl text-center mb-5 font-bold">
              Login
              <hr class="mt-4 border-b-1 border-blueGray-300" />
            </div>
            
            <form @submit.prevent="submit">
              <div class="relative w-full mb-3 mt-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Usuário
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite seu Usuario"
                  v-model="nomeUsuario"
                />

              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Senha
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                  placeholder="Digite sua Senha"
                  v-model="senha"
                />
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-redGray-100 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Logar
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex-wrap relative">
          <div class="w-1 text-center">
            <router-link to="/cadastro" class="text-blueGray-200">
              <h5>Não tem conta? Cadastre-se</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {
  name: "Login",
  setup() {
    const nomeUsuario = ref('');
    const senha = ref('');
    const router = useRouter();

    const submit = async () => {
      await axios.post('login', {
        nome_usuario: nomeUsuario.value,
        senha: senha.value
      }).then(response => {
        window.location.href = '/admin';
      }).catch(error => {
        console.log(error);
        Toast.fire("Ops...", error.response.data.message, "error");
      });
    }

    return {
      nomeUsuario,
      senha,
      submit
    };
  },
};
</script>
