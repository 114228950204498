import { Perfil } from "./perfil";
export class Usuario {
    id;
    nome;
    nome_usuario;
    email;
    perfil;
    permissoes;
    constructor(id = 0, nome = '', nome_usuario = '', email = '', perfil = new Perfil(), permissoes = []) {
        this.id = id;
        this.nome = nome;
        this.nome_usuario = nome_usuario;
        this.email = email;
        this.perfil = perfil;
        this.permissoes = permissoes;
    }

    canView(pagina) {
        return this.permissoes.some(p => p === `ver_${pagina}`);
    }

    canEdit(pagina) {
        return this.permissoes.some(p => p === `editar_${pagina}`);
    }

    canDelete(pagina) {
        return this.permissoes.some(p => p === `excluir_${pagina}`);
    }
}