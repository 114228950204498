<template>
   <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
        <div class="flex-auto p-10 lg:p-10">
            <h4 class="text-2xl font-semibold">
                Ver Conteúdo              
            </h4>
            <div class="relative w-full mb-3 mt-8">
            <QuillEditor 
                theme="snow" 
                toolbar="full" 
                :options="options"
                v-model:content="conteudo"
                content-type="html"
                required
            />
            </div>
            <div class="text-center mt-6">
                <router-link to="/admin/postagens">
                    <button 
                    class="text-white-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                    type="button" 
                    >
                        Voltar
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
export default {
    name: "VerConteudo",
    components: {QuillEditor},
    setup () {
        const conteudo = ref("");
        const router = useRouter();
        const {params} = useRoute();
        
        onMounted(async () => {
            const response = await axios.get(`postagens/${params.id}`);
            const postagem = response.data.data;
            conteudo.value = postagem.conteudo;
            console.log(conteudo.value);
        });
        return {
            conteudo,
            options: {
                modules: {
                    toolbar: ['bold', 'italic', 'underline']
                },
                theme: 'snow',
                readOnly: true,
            }
        }
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      }, 
    },
}
</script>

<style>

</style>