import { Permissao } from './permissao';
export class Perfil {
    id;
    nome;
    permissoes = [];
    
    constructor(id = 0, nome = '', permissoes = []) {
        this.id = id;
        this.nome = nome;
        this.permissoes = permissoes;
    }
}