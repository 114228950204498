import { createStore } from 'vuex'
import UsuarioModule from './UsuarioModule'

export default createStore({
  state: {},
  getters: {
  },
  mutations: {},
  actions: {},
  modules: {
    Usuario: UsuarioModule,
  }
})
