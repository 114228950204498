<template>
    <label class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-base px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
        Upload
        <input 
            type="file"
            hidden
            @change="upload($event.target.files)"
            >
    </label>
</template>

<script>
import axios from 'axios';

export default {
    name: "ImagemUpload",
    emits: ['file-uploaded'],
    setup(_, context) {
        const upload = async (files) => {
            const file = files.item(0);

            if (!file) {
                return;
            }

            const data = new FormData;
            data.append('imagem', file);

            const response = await axios.post('upload', data);
            
            // emite para a página de destino a url da imagem que foi carregada
            context.emit('file-uploaded', response.data.url);
        }

        return {
            upload
        }
    }
}
</script>

<style>

</style>