import { createRouter, createWebHistory } from 'vue-router'

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Usuarios from "@/views/admin/usuarios/Usuarios.vue";
import Perfis from "@/views/admin/perfis/Perfis.vue";
import Categorias from "@/views/admin/categorias/Categorias.vue";
import Postagens from "@/views/admin/postagens/Postagens.vue";
import CriarPostagem from "@/views/admin/postagens/CriarPostagem.vue";
import EditarPostagem from "@/views/admin/postagens/EditarPostagem.vue";
import VerConteudo from "@/views/admin/postagens/VerConteudo.vue";

import Perfil from "@/views/admin/perfil/Perfil.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Cadastro from "@/views/auth/Cadastro.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";
import axios from 'axios';


const routes = [
    {
        path: "/admin",
        redirect: "/admin/usuarios",
        component: Admin,
        beforeEnter: (to, from, next) => {
          guard(to, from, next);
        },
        children: [
          {
            path: "/admin/usuarios",
            component: Usuarios,
            meta: { title: 'Listar Usuários' },
          },
          {
            path: "/admin/perfis",
            component: Perfis,
            meta: { title: 'Listar Perfis' },
          },
          {
            path: "/admin/categorias",
            component: Categorias,
            meta: { title: 'Listar Categorias' },
          },
          {
            path: "/admin/postagens",
            component: Postagens,
            meta: { title: 'Listar Postagens' },
          },
          {
            path: "/admin/postagens/criar",
            component: CriarPostagem,
            meta: { title: 'Criar Postagem' },
          },
          {
            path: "/admin/postagens/:id/editar",
            component: EditarPostagem,
            meta: { title: 'Editar Postagem' },
          },
          {
            path: "/admin/postagens/:id/conteudo",
            component: VerConteudo,
            meta: { title: 'Ver Conteudo' },
          },
          {
            path: "/admin/perfil",
            component: Perfil,
            meta: { title: 'Meu Perfil' },
          },
        ],
      },
      {
        path: "/auth",
        redirect: "/login",
        component: Auth,
        children: [
          {
            path: "/login",
            component: Login,
            meta: { title: 'Login' },
          },
          {
            path: "/cadastro",
            component: Cadastro,
            meta: { title: 'Cadastro' },
          },
        ],
      },
      {
        path: "/landing",
        component: Landing,
      },
      {
        path: "/profile",
        component: Profile,
      },
      {
        path: "/",
        component: Index,
        meta: { title: 'BPA Channel' },
      },
      { path: "/:pathMatch(.*)*", redirect: "/" },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

const guard = async function(to, from, next) {
  // checa se pode acessar o usuario (minimo de acesso logado)
  await axios.get('usuario').then(response => {
    document.title = to.meta.title;
    next();
  }).catch(error => {
    // redireciona para login e dispara um alert de erro
    router.push('/login');
    Toast.fire("Oops...", "Não autenticado, por favor realize login!", "error");
  })
};

export default router
